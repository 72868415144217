<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      num: this.$route.path,
      isMobile: true
    }
  },
  mounted() {
    this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
    if (this.isMobile) {
      window.location.href = 'http://yltmzs.com/mobile'

    }
    window.onresize = () => {
      return (() => {
        this.isMobile = /Android|webOS|iPhone|iPad|BlackBerry/i.test(navigator.userAgent)
        if (this.isMobile) {
          window.location.href = 'http://yltmzs.com/mobile'
        }
      })()
    };
  },
  methods: {
  },
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: #faf9f7;
}
</style>
