import Vue from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/style/common.less'

/* svg */
import SvgIcon from '@/components/SvgIcon'
import './assets/icons/index'
Vue.component('svg-icon',SvgIcon)
/* <svg-icon icon-fill-name='xxxx'/>*/
/* el */

import ElementUI from 'element-ui';
import '../node_modules/element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

/* echarts */
import * as echarts from 'echarts'

/* 轮循 */
import vueSeamlessScroll from "vue-seamless-scroll";
Vue.component('vueSeamlessScroll',vueSeamlessScroll)

/* swiper */
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'
Vue.config.productionTip = false
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
