import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: ()=>import('@/views/index.vue'),
    children:[
      {
        path:'/home',
        name:'home',
        component:()=>import('@/views/home/index'),
        meta:{title:'首页'}
      },
      {
        path:'/data',
        name:'data',
        component:()=>import('@/views/data/index'),
        meta:{title:'指数数据'}
      },
      {
        path:'/data/:id',
        name:'data',
        component:()=>import('@/views/data/index'),
        meta:{title:'指数数据'}
      },
      {
        path:'/comment',
        name:'comment',
        component:()=>import('@/views/comment/index'),
        meta:{title:'指数点评'}
      },
      {
        path:'/comment/:id',
        name:'comment',
        component:()=>import('@/components/commentDetail/index'),
        meta:{title:'指数点评'}
      },
      {
        path:'/news',
        name:'news',
        component:()=>import('@/views/news/index'),
        meta:{title:'新闻资讯'}
      },
      {
        path:'/news/:id',
        name:'news',
        component:()=>import('@/components/newsDetail/index.vue'),
        meta:{title:'新闻资讯'}
      },
      {
        path:'/enter',
        name:'enter',
        component:()=>import('@/views/enter/index'),
        meta:{title:'优质企业'}
      },
      {
        path:'/enter/:id',
        name:'enter',
        component:()=>import('@/components/enterDetail/index.vue'),
        meta:{title:'优质企业'}
      },
      {
        path:'/walkinto',
        name:'walkinto',
        component:()=>import('@/views/walkinto/index'),
        meta:{title:'走进彝良'}
      },
      {
        path:'/know',
        name:'know',
        component:()=>import('@/views/know/index'),
        meta:{title:'了解天麻'}
      },
      {
        path:'/introduce',
        name:'introduce',
        component:()=>import('@/views/introduce/index'),
        meta:{title:'指数介绍'}
      },
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
